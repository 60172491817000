.section {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  margin-top: 100px;
}

.container {
  grid-column-gap: 16px;
  justify-content: center;
  width: 100%;
  display: flex;
  position: static;
}

.containerWrapper {
  width: 100%;
  margin-bottom: -32px;
  position: relative;
}

.sectionCarousel {
  z-index: 100;
  /* padding: 16px 0%; */
  position: relative;
  top: -16px;
  overflow: hidden;
}

.testimonialCarouselContainer {
  grid-row-gap: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.testimonialCarouselWrapL {
  flex: none;
  width: 100%;
  display: flex;
}

.testimonialCarouselLeft:hover,
.testimonialCarouselRight:hover {
  animation-play-state: paused;
}
.testimonialCarouselLeft {
  animation: carousel-left 200s linear infinite;
  flex: none;
  align-items: center;
  display: flex;
  align-items: flex-start;
}

.testimonialCarouselWrapR {
  flex: none;
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.testimonialCarouselRight {
  animation: carousel-right 200s linear infinite;
  flex: none;
  align-items: center;
  display: flex;
}

@keyframes carousel-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - 0px));
  }
}

@keyframes carousel-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(50% + 0px));
  }
}

.card {
  width: 450px;
}
